import React, {useState} from 'react';
import "./interestMap.scss";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

import BarChart from '../../components/interestMapChart/interestMapChart'
import ParameterBox from "../../components/parameterBox/parameterBox";

import geoJson from '../../data/Map/countries.geojson'
import lacAnnecyData from '../../data/Map/Clear_Lac-Annecy-recherches-par-pays.csv'
import worldResearch from '../../data/Map/world_research.csv'

class InterestMap extends React.Component {

    constructor(props) {
        super(props);
        this.updatePopupDataParam = this.updatePopupDataParam.bind(this);
    }

    csvSplit(csvData){
        let data = {},
            lineFields,
            csvLines = csvData.split("\n").slice(1);
        csvLines.forEach(function (line, i) {
            lineFields = line.split(',');
            data[i] = lineFields;
        });

        return data;
    }

    componentDidMount() {

        mapboxgl.accessToken = 'pk.eyJ1IjoidGhvbWFzZ2hpZ25vbiIsImEiOiJja3dmMmo3eG8wYW1kMnBwbXNneWpuZGt0In0.Bp1XaLOyuN0hvTHRgVDuTQ';

        const map = new mapboxgl.Map({
            container: 'map', // container ID
            style: 'mapbox://styles/thomasghignon/ckwxdvudjgpiv15nvpfmbb8t5', // style URL
            center: [20, 40], // starting position [lng, lat]
            zoom: 2 // starting zoom
        });

        let hoveredCountryId = null;

        map.on('load', async () => {
            const csvLacAnnecyData = await fetch(lacAnnecyData).then((response) => response.text());
            const csvDataWorldResearch = await fetch(worldResearch).then((response) => response.text());

            let clearLacAnnecyData = [];
            //let clearWorldResearchData = [];

            let lacAnnelyDataSplit = this.csvSplit(csvLacAnnecyData);
            let worldResearchDataSplit = this.csvSplit(csvDataWorldResearch);

            for (let index in lacAnnelyDataSplit){

                let check = false;

                for (let i in clearLacAnnecyData){
                    if (i === lacAnnelyDataSplit[index][1]){
                        check = true;
                    }
                }

                if (check){
                    clearLacAnnecyData[lacAnnelyDataSplit[index][1]].push([lacAnnelyDataSplit[index][0], lacAnnelyDataSplit[index][3]]);
                }else{
                    clearLacAnnecyData[lacAnnelyDataSplit[index][1]] = [[lacAnnelyDataSplit[index][0], lacAnnelyDataSplit[index][3]]];
                }
            }

            for (let i in clearLacAnnecyData){

                let cpt = 0;
                for (let j in clearLacAnnecyData[i]){
                    cpt = cpt + Number(clearLacAnnecyData[i][j][1]);
                }
                clearLacAnnecyData[i].push(cpt);
            }





            // BEGIN GEOJSON
            const geojsonData = await fetch(geoJson).then((response) => response.json());
            for (const f of geojsonData.features) {

                let totalSessions  = 0;
                let totalResearch = 0;

                let worldResearchDataLine = Object.values(worldResearchDataSplit).filter(checkCountryName => f.properties.ADMIN === checkCountryName[0])
                let lacAnnecyDataLine = Object.values(lacAnnelyDataSplit).filter(checkCountryName => f.properties.ADMIN === checkCountryName[1])

                if (worldResearchDataLine.length !== 0){
                    for (let j in worldResearchDataLine){

                        if (Number(worldResearchDataLine[j][2]) > 1){
                            totalSessions = totalSessions + Number(worldResearchDataLine[j][2]);
                        }

                        switch(Number(worldResearchDataLine[j][1])) {
                            case 2016:
                                f.properties.sessions2016 = Number(worldResearchDataLine[j][2]);
                                break;
                            case 2017:
                                f.properties.sessions2017 = Number(worldResearchDataLine[j][2]);
                                break;
                            case 2018:
                                f.properties.sessions2018 = Number(worldResearchDataLine[j][2]);
                                break;
                            case 2019:
                                f.properties.sessions2019 = Number(worldResearchDataLine[j][2]);
                                break;
                            case 2020:
                                f.properties.sessions2020 = Number(worldResearchDataLine[j][2]);
                                break;
                            case 2021:
                                f.properties.sessions2021 = Number(worldResearchDataLine[j][2]);
                                break;
                            default:

                        }
                    }

                }

                if (lacAnnecyDataLine.length !== 0){
                    //console.log(lacAnnecyDataLine)
                    /*for (let j in lacAnnecyDataLine){

                        if (Number(lacAnnecyDataLine[j][2]) > 1){
                            totalSessions = totalSessions + Number(lacAnnecyDataLine[j][2]);
                        }

                        switch(Number(lacAnnecyDataLine[j][1])) {
                            case 2016:
                                f.properties.sessions2016 = Number(lacAnnecyDataLine[j][2]);
                                break;
                            case 2017:
                                f.properties.sessions2017 = Number(lacAnnecyDataLine[j][2]);
                                break;
                            case 2018:
                                f.properties.sessions2018 = Number(lacAnnecyDataLine[j][2]);
                                break;
                            case 2019:
                                f.properties.sessions2019 = Number(lacAnnecyDataLine[j][2]);
                                break;
                            case 2020:
                                f.properties.sessions2020 = Number(lacAnnecyDataLine[j][2]);
                                break;
                            case 2021:
                                f.properties.sessions2021 = Number(lacAnnecyDataLine[j][2]);
                                break;
                            default:

                        }
                    }*/

                }

                f.properties.ttSessions = totalSessions;

                /*if (f.properties.ADMIN === "United States of America"){
                    console.log(f.properties)
                }*/

            }


            //console.log(clearLacAnnecyData);
            //console.log(Object.values(lacAnnelyDataSplit));
            //console.log('Analytics data :>> ', worldResearchDataSplit);
            //console.log('geojson :>> ', geojsonData);
            // END GEOJSON

            map.addSource('countries', {
                'type': 'geojson',
                'data': geojsonData,
                'generateId': true
            });

            map.addLayer({
                'id': 'country-fill',
                'type': 'fill',
                'source': 'countries',
                'layout': {},
                'paint': {
                    "fill-color": [
                        "case",
                        [">", ["get", "ttSessions"], 1000000],
                        "#AF3AF4",
                        [">", ["get", "ttSessions"], 100000],
                        "rgba(175,58,244,0.74)",
                        [">", ["get", "ttSessions"], 10000],
                        "rgba(175,58,244,0.48)",
                        [">", ["get", "ttSessions"], 1],
                        "rgba(175,58,244,0.18)",
                        ["<", ["get", "ttSessions"], 1],
                        "rgba(175,58,244,0)",
                        "#077",
                    ],
                    "fill-opacity": [
                        "case",
                        ["boolean", ["feature-state", "hover"], false],
                        0.5,
                        0.4,
                    ],
                },
            });
        });

        let namePopup = document.getElementById('namePopup');
        let dataPopup = document.getElementById('dataPopup');


        const onMouseMove = (e) =>{
            namePopup.style.left = e.offsetX - 20 + 'px';
            namePopup.style.top = e.offsetY - 20 + 'px';
            dataPopup.style.left = e.offsetX - 20 + 'px';
            dataPopup.style.top = e.offsetY - 20 + 'px';
        }

        document.addEventListener('mousemove', onMouseMove);


        map.on('mousemove', 'country-fill', (e) => {

            if (e.features.length > 0) {
                if (hoveredCountryId !== null) {
                    map.setFeatureState(
                        { source: 'countries', id: hoveredCountryId },
                        { hover: false }
                    );

                    namePopup.firstChild.innerHTML = e.features[0].properties.ADMIN;

                    const nbSessions = e.features[0].properties.ttSessions
                    if ( nbSessions > 1){
                        namePopup.lastChild.innerHTML = e.features[0].properties.ttSessions.toLocaleString() + " sessions";
                    }else{
                        namePopup.lastChild.innerHTML = e.features[0].properties.ttSessions + " session";
                    }

                    namePopup.classList.remove("hidden");
                }
                hoveredCountryId = e.features[0].id;
                map.setFeatureState(
                    { source: 'countries', id: hoveredCountryId },
                    { hover: true }
                );
            }
        });

        map.on('mouseleave', 'country-fill', () => {
            if (hoveredCountryId !== null) {
                map.setFeatureState(
                    { source: 'countries', id: hoveredCountryId },
                    { hover: false }
                );
                namePopup.classList.add("hidden");
            }
            hoveredCountryId = null;
        });

        let dataPopupState = false;

        map.on('click', 'country-fill', (e) => {
            if (e.features.length > 0) {
                if (hoveredCountryId !== null) {
                    namePopup.classList.toggle("hidden");
                    dataPopup.classList.toggle("hidden");

                    if (dataPopupState === false){

                        document.removeEventListener('mousemove', onMouseMove);
                        dataPopupState = true;

                    }else{

                        if(e.target !== dataPopup) {
                            document.addEventListener('mousemove', onMouseMove);
                            dataPopupState = false;
                        }
                    }
                }
            }
            this.stateChange(e.features[0].properties, this.checkState)
        });
    }

    state = {
        test: 'test1',
        labels: [],
        data: [],

    }

    stateChange = (e, type) => {

        if(type){
            this.setState({
                ['countryName']: e.ADMIN + ' (A implémenter)',
                ['item1']: 20,
                ['item2']: 10,
                ['item3']: 36,
                ['item4']: 1,
                ['item5']: 90,
                ['item6']: 65,
                ['ttSessions']: 40,
            });
        }else{
            this.setState({
                ['countryName']: e.ADMIN,
                ['item1']: e.sessions2016,
                ['item2']: e.sessions2017,
                ['item3']: e.sessions2018,
                ['item4']: e.sessions2019,
                ['item5']: e.sessions2020,
                ['item6']: e.sessions2021,
                ['ttSessions']: e.ttSessions,
            });
        }
    }

    checkState = false;

    updatePopupDataParam(e, state){

        this.checkState = state;
    }

    render() {
        return (
            <div className="container">
                <div id="namePopup" className="hidden">
                    <p>Country name</p>
                    <p>Number of sessions</p>
                </div>
                <div id="dataPopup" className="hidden">
                    <BarChart data = {this.state} />
                </div>
                <ParameterBox updateData={this.updatePopupDataParam}/>
                <div className="interestMap map" id="map"/>
                <div className="interestMap_legend">
                    <p>Nombre de sessions utilisateur créé sur lac-annecy.com entre 2016 et 2021</p>
                    <div>
                        <div><div></div><span>> 1 000 000</span></div>
                        <div><div></div><span>> 100 000</span></div>
                        <div><div></div><span>> 10 000</span></div>
                        <div><div></div><span>> 1</span></div>
                        <div><div></div><span>0</span></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InterestMap;

