//import useState hook to create menu collapse state
import React, {useState} from "react";

import {NavLink} from "react-router-dom";

//import icons from react icons
import {CgMenuRight, CgChevronRight} from "react-icons/cg";
import {MdTravelExplore} from "react-icons/md";
import {BsPinMapFill} from "react-icons/bs";

import "./sidebar.scss";
import {BsGraphUp} from "react-icons/all";


export default function Sidebar() {

    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(false)

    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        if (menuCollapse) {
            setMenuCollapse(false);
            document.getElementById('sidebar').classList.remove('collapsed');
            document.querySelector('.page').classList.remove('expanded');
        } else {
            setMenuCollapse(true);
            document.getElementById('sidebar').classList.add('collapsed');
            document.querySelector('.page').classList.add('expanded');
        }
    };

    return (
        <div id="sidebar" className="sidebar">
            {/* collapsed props to change menu size using menucollapse state */}
            <div collapsed={menuCollapse}>
                <div className="sidebar-header">
                    <div className="sidebar-header-logo" onClick={menuIconClick}>
                        {/* small and big change using menucollapse state */}
                        <div><CgMenuRight/></div>
                    </div>
                </div>
                <nav className="sidebar-nav">
                    <ul>
                        <li>
                            <NavLink to="/" title="Interest Map">
                                <MdTravelExplore className="navLinkIcon"/>
                                <span className="navLinkText">Pays</span>
                                <CgChevronRight className="navLinkChevron"/>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="touristes" title="Heat Map">
                                <BsPinMapFill className="navLinkIcon"/>
                                <span className="navLinkText">Départements</span>
                                <CgChevronRight className="navLinkChevron"/>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="visitors" title="Visiteurs">
                                <BsGraphUp className="navLinkIcon"/>
                                <span className="navLinkText">Visiteurs</span>
                                <CgChevronRight className="navLinkChevron"/>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};