import React, {useState} from 'react';
import "./parameterBox.scss";

function ParameterBox({updateData}){

    const [checkState, setCheckState] = useState(
        true
    );
    function paramUpdate(e){
        setCheckState(!checkState);
        updateData(e, checkState);
    }
    return(
        <div className="parameterBoxContainer" id="parameterBoxContainer">
            <p>Paramètre</p>
            <label className="switch">
                <input id="sessionsParam" className="inputParam" type="checkbox" onChange={paramUpdate} checked={checkState}/>
                <div>
                    <span>Sessions</span>
                </div>
            </label>
            <label className="switch">
                <input id="interestParam" className="inputParam" type="checkbox" onChange={paramUpdate} checked={!checkState}/>
                    <div>
                        <span>Intérêt</span>
                    </div>
            </label>
        </div>
    )
}

export default ParameterBox;