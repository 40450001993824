import React from "react";
import {
    Chart as ChartJS, CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import {Line} from 'react-chartjs-2';
import {readString} from 'react-papaparse'
import frequentationBureau from '../../data/Frequentation/frequentationBureau_cleard.csv'

ChartJS.register(CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend)

class VisitorsPerYears extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chartOption: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Nombre de visiteurs de 2012 à 2020',
                    },
                },
            },
            visitorsData: {
                labels: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'],
                datasets: [
                    {
                        label: "test curve",
                        data: [],
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        showLine: true
                    }
                ]
            }
        }

    }


    render() {

        return (
            <div className="visitorsPerYears main-component">
                <Line options={this.state.chartOption} data={this.state.visitorsData} redraw={true}/>
            </div>
        )
    }

    hideCurve(dataset) {
        return dataset.showLine = !dataset.showLine
    }

    componentDidMount() {
        this.dataTransformToObject()
    }

    dataTransformToObject() {
        let data;

        fetchData().then(() => {
            this.visitorsChart(data)
        })

        const config = {
            header: true,
            complete: (results) => {
                data = results
            }
        }

        async function fetchData() {
            const csvData = await fetch(frequentationBureau).then((response) => response.text());
            readString(csvData, config);
        }
    }

    visitorsChart(data) {

        let years = {};
        let datasetsDynamic = []

        for (const line of data.data) {
            years['a' + line.Année] = years['a' + line.Année] || []
            years['a' + line.Année].push(parseInt(line.Total, 10))
        }

        let indexColor = 0;
        const colors = [
            '#af4af3', '#ff2fbe', '#ff5687', '#ff925d', '#ffc950', '#f9f871', '#3b0085', '#31beaf', '#008d79'
        ]

        for (const yearSolo in years) {
            datasetsDynamic.push({
                label: yearSolo.slice(1),
                data: years[yearSolo],
                borderColor: colors[indexColor],
                backgroundColor: colors[indexColor],
                showLine: true
            })
            indexColor++
        }


        this.setState({
            visitorsData: {
                labels: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'],
                datasets: datasetsDynamic
            }
        })

    }

}

export default VisitorsPerYears;