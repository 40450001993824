import './App.scss';

/* Routes */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./pages/notFound";
import InterestMap from "./pages/interestMap/interestMap";
import HeatMapTouristes from "./pages/heatMapTouristes/heatMapTouristes";
import Home from "./pages/home";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Sidebar from './components/sidebar/sidebar';
import VisitorsPerYears from "./pages/VisitorsPerYears/VisitorsPerYears";

const theme = createTheme({
  palette: {
    primary: {
      main: "#af4af3"
    },
    secondary: {
      main: "#3e64ff"
    }
  }
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Sidebar />
          <div className="page">
            <div className="page-content">
              <Routes>
                {/* 404 */}
                <Route path="*" element={<NotFound />} />
                {/* Home */}
                <Route exact path="/" element={<InterestMap />} />
                {/* Pages */}
                <Route exact path="visitors" element={<VisitorsPerYears />}/>
                <Route exact path="touristes" element={<HeatMapTouristes />} />
              </Routes>
            </div>
            {/*<Footer />*/}
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;