import React from 'react';
import "./heatMapTouristes.scss";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import touristesData from '../../data/Frequentation/Frequentations.json'
import HeatMapBox from "../../components/heatMapBox/heatMapBox";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';


const config = {
    worker: true,
    complete: (results) => {
        // console.log(results)
    }
}


class HeatMapTouristes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chosenCity: 'ANNECY',
            chosenMonth: 'MAI'
        };

        this.changeCity = this.changeCity.bind(this);
        this.changeMonth = this.changeMonth.bind(this);
    }

    changeCity(event, newCity) {
        this.setState({ chosenCity: newCity });
        this.loadMap();
    };

    changeMonth(event, newMonth) {
        this.setState({ chosenMonth: newMonth });
        this.loadMap();
    };

    loadMap() {
        mapboxgl.accessToken = 'pk.eyJ1IjoidGNoMHV0Y2gwdSIsImEiOiJja3dmMnFhNGIwYWwwMndtdWZ2Z2llaGUzIn0.0LSMia3LHgf6KNcFI_r5dw';

        const map = new mapboxgl.Map({
            container: 'map', // container ID
            style: 'mapbox://styles/mapbox/light-v10', // style URL
            center: [4.5, 45.5],
            zoom: 7,
        });

        let activeDeptId = null;
        const touristesAura = document.getElementById('touristesAura');
        const touristesNoAura = document.getElementById('touristesNoAura');
        const touristesFrance = document.getElementById('touristesFrance');

        map.on('load', async () => {
            const geojsonData = await fetch("https://france-geojson.gregoiredavid.fr/repo/departements.geojson").then((response) => response.json());
            for (const f of geojsonData.features) {
                for (const dataDeptName in touristesData[this.state.chosenCity][this.state.chosenMonth]) {
                    let upperCaseDeptName = f.properties.nom.toUpperCase();
                    if (upperCaseDeptName == dataDeptName.toUpperCase()) {
                        f.properties.touristes = touristesData[this.state.chosenCity][this.state.chosenMonth][dataDeptName];
                    }
                    else if ("Total demande France" === dataDeptName) {
                        touristesFrance.innerHTML = touristesData[this.state.chosenCity][this.state.chosenMonth][dataDeptName];
                    }
                    else if ("Total demande région AURA" === dataDeptName) {
                        touristesAura.innerHTML = touristesData[this.state.chosenCity][this.state.chosenMonth][dataDeptName];
                    }
                    else if ("Total demande hors région AURA" === dataDeptName) {
                        touristesNoAura.innerHTML = touristesData[this.state.chosenCity][this.state.chosenMonth][dataDeptName];
                    }
                }
            }

            map.addSource("departements", {
                type: "geojson",
                data: geojsonData,
                generateId: true,
            });

            map.addLayer({
                id: "departements-fill",
                type: "fill",
                source: "departements",
                layout: {},
                paint: {
                    "fill-color": [
                        "case",
                        [">", ["get", "touristes"], 300],
                        "rgba(175,58,244,1.0)",
                        [">", ["get", "touristes"], 200],
                        "rgba(175,58,244,0.9)",
                        [">", ["get", "touristes"], 100],
                        "rgba(175,58,244,0.8)",
                        [">", ["get", "touristes"], 50],
                        "rgba(175,58,244,0.7)",
                        [">", ["get", "touristes"], 40],
                        "rgba(175,58,244,0.6)",
                        [">", ["get", "touristes"], 30],
                        "rgba(175,58,244,0.5)",
                        [">", ["get", "touristes"], 20],
                        "rgba(175,58,244,0.4)",
                        [">", ["get", "touristes"], 10],
                        "rgba(175,58,244,0.3)",
                        [">", ["get", "touristes"], 5],
                        "rgba(175,58,244,0.2)",
                        ["<=", ["get", "touristes"], 5],
                        "rgba(175,58,244,0.1)",
                        "#999",
                    ],
                    "fill-opacity": [
                        "case",
                        ["boolean", ["feature-state", "hover"], false],
                        0.5,
                        0.4,
                    ],
                },
            });

            map.addLayer({
                id: "departements-borders",
                type: "line",
                source: "departements",
                layout: {},
                paint: {
                    "line-color": "#888",
                    "line-width": 2,
                },
            });

            // BEGIN LABEL POPUP
            let namePopup = document.getElementById('namePopup');

            const onMouseMove = (e) => {
                namePopup.style.left = e.offsetX - 10 + 'px';
                namePopup.style.top = e.offsetY - 10 + 'px';
            }

            document.addEventListener('mousemove', onMouseMove);

            map.on('mousemove', 'departements-fill', (e) => {
                if (e.features.length > 0) {
                    if (activeDeptId !== null) {
                        map.setFeatureState(
                            { source: 'departements', id: activeDeptId },
                            { hover: false }
                        );

                        namePopup.firstChild.innerHTML = e.features[0].properties.nom;
                        const nbTouristes = e.features[0].properties.touristes

                        if (nbTouristes > 1) {
                            namePopup.lastChild.innerHTML = e.features[0].properties.touristes.toLocaleString() + " touristes";
                        } else {
                            namePopup.lastChild.innerHTML = e.features[0].properties.touristes + " touriste";
                        }

                        namePopup.classList.remove("hidden");
                    }
                    activeDeptId = e.features[0].id;
                    map.setFeatureState(
                        { source: 'departements', id: activeDeptId },
                        { hover: true }
                    );
                }
            });

            map.on('mouseleave', 'departements-fill', () => {
                if (activeDeptId !== null) {
                    map.setFeatureState(
                        { source: 'departements', id: activeDeptId },
                        { hover: false }
                    );
                    namePopup.classList.add("hidden");
                }
                activeDeptId = null;
            });
            // END LABEL POPUP
        });
    }

    componentDidMount() {
        this.loadMap();
    }

    render() {
        return (
            <div className='heatmap'>
                <div className='heatmap-container'>
                    <div className='heatmap-header'>
                        <h1 className='page-title'>Provenance des touristes par département</h1>
                        <div className='heatmap-options'>
                            <ToggleButtonGroup onChange={this.changeCity} value={this.state.chosenCity} color="primary" aria-label="chosen city" exclusive>
                                <ToggleButton value="ANNECY" aria-label="ANNECY">Annecy</ToggleButton>
                                <ToggleButton value="SAINT-JORIOZ" aria-label="SAINT-JORIOZ">Saint-Jorioz</ToggleButton>
                                <ToggleButton value="TALLOIRES" aria-label="Talloires">Talloires</ToggleButton>
                                <ToggleButton value="VEYRIER-DU-LAC" aria-label="Veyrier-du-lac">Veyrier-du-lac</ToggleButton>
                            </ToggleButtonGroup>
                            <ToggleButtonGroup className='heatmap-options-months' onChange={this.changeMonth} value={this.state.chosenMonth} color="primary" aria-label="chosen month" exclusive>
                                <ToggleButton value="MAI" aria-label="MAI">Mai</ToggleButton>
                                <ToggleButton value="JUIN" aria-label="JUIN">Juin</ToggleButton>
                                <ToggleButton value="JUILLET" aria-label="JUILLET">Juillet</ToggleButton>
                                <ToggleButton value="AOÛT" aria-label="AOÛT">Août</ToggleButton>
                                <ToggleButton value="SEPTEMBRE" aria-label="SEPTEMBRE">Septembre</ToggleButton>
                                <ToggleButton value="OCTOBRE" aria-label="OCTOBRE">Octobre</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                    <div className="heatMapTouristes map" id="map"></div>
                    <div id="namePopup" className="hidden">
                        <p>Departement name</p>
                        <p>Number of tourists</p>
                    </div>
                    <div className="heatmap-legend">
                        <p>Nombre de touristes</p>
                        <div className='heatmap-legend-inner'>
                            <div><div></div><span>> 300</span></div>
                            <div><div></div><span>> 200</span></div>
                            <div><div></div><span>> 100</span></div>
                            <div><div></div><span>> 50</span></div>
                            <div><div></div><span>> 40</span></div>
                            <div><div></div><span>> 30</span></div>
                            <div><div></div><span>> 20</span></div>
                            <div><div></div><span>> 10</span></div>
                            <div><div></div><span>> 5</span></div>
                            <div><div></div><span>> 0</span></div>
                        </div>
                    </div>
                    <HeatMapBox />
                </div>
            </div>
        );
    }
}

export default HeatMapTouristes;