import React, {useState} from 'react';
import "./heatMapBox.scss";

function HeatMapBox(){

    const [checkState, setCheckState] = useState(
        true
    );
    return(
        <div className="heatMapBoxContainer" id="heatMapBoxContainer">
            <p>
                <span>Région Auvergne-Rhône-Alpes : </span><span id='touristesAura'></span>
            </p>
            <p>
                <span>Hors région AURA : </span><span id='touristesNoAura'></span>
            </p>
            <h3>Total France : <span id='touristesFrance'></span></h3>
        </div>
    )
}

export default HeatMapBox;