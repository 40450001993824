import React, {useState} from 'react';
import {Doughnut} from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'



const BarChart = (props) => {

    return (
        <div>
            <h2>{props.data.countryName}</h2>
            <Doughnut
                data={{
                        labels: ['2016', '2017', '2018', '2019', '2020', '2021'],
                        datasets: [{
                            label: '# of Votes',
                            data: [props.data.item1, props.data.item2, props.data.item3, props.data.item4, props.data.item5, props.data.item6],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth: 1
                        }]
                }}
            />
            {props.data.ttSessions ? (
                <p>{props.data.ttSessions.toLocaleString()}</p>
            ) : (
                <p>Empty</p>
            )}
        </div>
    )
}

export default BarChart;